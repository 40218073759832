import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Attique Ur Rehman </span>
            from <span className="purple"> Pakistan.</span>
            <br /> Embarking upon the threshold of 2017, I embarked upon a
            wondrous odyssey, my first foray into the realms of JavaScript,
            within the vibrant cityscape of Lahore. My fervent quest for
            knowledge, fueled by the embers of curiosity, led me to traverse the
            corridors of academia, emerging victorious in 2019, adorned with the
            laurels of graduation. Ever since, my footsteps have danced to the
            rhythm of the software development industry, orchestrating
            symphonies of innovation and breathing life into captivating mobile
            and web applications. With each line of code, I sculpt vibrant
            tapestries of digital marvels, blending artistry and logic, and
            leaving an indelible mark upon the digital frontier. Let the
            chapters of this captivating journey continue to unfold, as I chase
            the horizons of possibility, driven by an insatiable passion to
            craft extraordinary experiences that transcend the boundaries of
            ordinary perception.
            <br />
            Additionally, i'm working as a full time freelancer on upwork.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Astrology Research
            </li>
            <li className="about-activity">
              <ImPointRight /> Cricket
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Technology is not just a tool, but an ever-evolving canvas for
            innovation, creativity, and limitless possibilities. Embrace the
            power within your hands to shape the world and inspire generations
            to come"{" "}
          </p>
          <footer className="blockquote-footer">Attique</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
